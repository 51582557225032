import axios from 'axios';

const api = axios.create({
    baseURL: 'http://api.gcreativehub.co.in',
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
    },
});

api.interceptors.request.use(
    (config) => {
        config.headers['Access-Control-Allow-Origin'] = '*';
        config.headers['Access-Control-Allow-Methods'] = 'GET, POST, PUT, DELETE';
        config.headers['Access-Control-Allow-Headers'] = 'Content-Type, Authorization';
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => {
        console.log('Response:', response.data);
        return response;
    },
    (error) => {
        console.error('Error:', error.response);
        return Promise.reject(error);
    }
);

// Function to handle API requests with success and error messages
export const handleApiRequest = async (
    endpoint = 'submit-form',
    method = 'post',
    data = null,
    { success = () => { },
        error = () => { },
        show = () => { } } = {}
) => {
    try {
        const response = await api.request({
            method,
            url: endpoint,
            data,
        });
        success(response.data.message || "Thank you for your request")
        return response.data;
    } catch (err) {
        error(err.message)
        throw err;
    }
};

export default api;
