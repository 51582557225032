import { useEffect } from "react";
import { useLocation, Routes, Route } from "react-router-dom";
import PrivacyPolicy from './pages/PrivacyPolicy'
import Home from "./pages/Homes/Home";
import AboutUsPage from "./pages/AboutUsPage";
import ProjectPage from "./pages/Projects/ProjectPage";
import EstimatePage from "./pages/EstimatePage";
import ShopPage from "./pages/ShopPages/ShopPage";
import ContactPage from "./pages/ContactPage";
import SiteMap from './pages/SiteMap';
import Facilities from './pages/Facilities'


function App() {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<AboutUsPage />} />
      <Route path="/gallery" element={<ProjectPage />} />
      <Route path="/estimate" element={<EstimatePage />} />
      <Route path="/explore-products/:product-type" element={<ShopPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/site-map" element={<SiteMap />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/facilities" element={<Facilities />} />
    </Routes>
  );
}

export default App;
