import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import { handleApiRequest } from '../../lib/api-util';
import { productDescription } from '../../data/product-desc'

const ShopItem = ({ item, selectedProduct, handleEnquiryClick, handleClosePopup }) => {

  const alert = useAlert();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    details: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      formData.formId = 101;
      formData.productName = item.productName;
      await handleApiRequest('submit-form', 'post', formData, alert);
    } catch (error) {
      console.error("Error submitting form data:", error);
    } finally {
      setFormData({
        name: '',
        email: '',
        phoneNumber: '',
        details: ''
      });
    }
  }

  return (
    <div className="shop-item">
      <div className="shop-thumb">
        <img src={item.productImage} alt={item.productName} style={{ borderRadius: '5px' }} />
      </div>

      {/* <div className="shop-content">
        <Link to="#" className="tag">
          {item.category}
        </Link>

        <h2 className="title">
          <Link to="#">{item.productName}</Link>
        </h2>
        <h3 style={{ color: 'black', fontSize: '10px' }}>{productDescription[item.category]}</h3>
        <h3 className="price">₹{item.productPricing} / Piece</h3>
        <Link to="" onClick={() => handleEnquiryClick(item)}
          className="enquiry-btn">Enquiry Product
        </Link>

      </div> */}

      {selectedProduct && (
        <div className="popup-overlay">
          <div className="popup-content">
            <div className="popup-header">
              <h3>Enquiry For {selectedProduct.productName}</h3>
              <button onClick={handleClosePopup} className="close-icon">X</button>
            </div>
            <hr></hr>

            <div className="popup-body">
              <div className="product-details">
                <div className="shop-item">
                  <div className="shop-thumb">
                    <img src={selectedProduct.productImage} alt={selectedProduct.productName} style={{ borderRadius: '5px' }} />
                  </div>
                  <div className="shop-content">
                    <Link to="#" className="tag">
                      {selectedProduct.category}
                    </Link>
                    <h2 className="title">
                      <Link to="#">{selectedProduct.productName}</Link>
                    </h2>
                    <h3 className="price">₹{selectedProduct.productPricing} / Piece</h3>
                  </div>
                </div>

              </div>
              <div className="enquiry-form">
                <h3>Submit Enquiry</h3>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <input type="text" name="name" className="form-control" placeholder="Your Name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <input type="email" name="email" className="form-control" placeholder="Your Email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <input type="tel" className="form-control"
                      name='phoneNumber'
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      placeholder="Phone Number" />
                  </div>
                  <div className="form-group">
                    <textarea name="details" className="form-control" rows={4} placeholder="Enter details about the product you're interested in" value={formData.details} onChange={handleChange}></textarea>
                  </div>

                  <button type="submit" className="btn small-btn">
                    Submit Enquiry
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShopItem;
