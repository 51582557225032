import React from "react";
import EstimateForm from "./EstimateForm";

const EstimateArea = () => {
  return (
    <section className="estimate-area pt-100 pb-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="estimate-form">
              <h2 className="title">Schedule Free Estimate</h2>
              <EstimateForm />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EstimateArea;
