import React, { useEffect } from "react";
import BreadcrumbArea from "../../components/BreadcrumbArea/BreadcrumbArea";
import ShopArea from "../../components/ShopAreas/ShopArea";
import Layout from "../../layouts/Layout";
import { gsapTitleAnimation } from "../../lib/gsap-lib/gsapTitleAnimation";
import { useParams } from "react-router-dom";
import { productDescription } from '../../data/product-desc'

const ShopPage = () => {

  const { 'product-type': productType } = useParams();


  useEffect(() => {
    gsapTitleAnimation();
  }, []);

  return (
    <Layout>
      <BreadcrumbArea title={productType} subtitle={productType} desc={productDescription[productType]} />
      <ShopArea />
    </Layout>
  );
};

export default ShopPage;
