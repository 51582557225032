import React from "react";
import Footer from "../components/Footers/FooterOne";
import Header from "../components/Headers/Header";
import PageLoader from "../components/PageLoader/PageLoader";
import ScrollToTopButton from "../components/ScrollToTopButton/ScrollToTopButton";

const Layout = ({
  children
}) => {

  return (
    <>
      <PageLoader />
      <ScrollToTopButton />
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
