export const ProjectList = [
  {
    url: "/explore-products/living",
    src: "/img/project/living-products.jpeg",
    src2: "/img/project/living-products.jpeg",
    title: "Living",
    description: "Elevate your living spaces with MR Exports' curated collection: luxurious curtains, stylish cushion covers, and comfortable chair pads. Our premium box mattresses ensure restful sleep, while cozy throws add warmth and charm. Explore our range for sophisticated home essentials crafted with quality and style in mind."
  },
  {
    url: "/explore-products/bedding",
    src: "/img/project/beding.jpg",
    src2: "/img/project/beding.jpg",
    title: "Bedding",
    description: "Indulge in luxury with MR Exports' bedding essentials: exquisite bedding sets, elegant bed covers, and plush duvet covers. Wrap yourself in comfort with our premium quilts and pillowcases, paired perfectly with our snug fitted sheets. Elevate your bedroom sanctuary with our collection, designed for unparalleled comfort and style."
  },
  {
    url: "/explore-products/kitchen",
    src: "/img/project/kitchen-products.jpeg",
    src2: "/img/project/kitchen-products.jpeg",
    title: "Kitchen",
    description: "Enhance your kitchen with MR Exports' essentials: stylish appliance covers, durable aprons, and charming mitten and potholder sets. Our absorbent kitchen towels and dish cloths complete the ensemble, adding both functionality and flair to your culinary space. Explore our collection for quality essentials designed to elevate your kitchen experience."
  },
  {
    url: "/explore-products/dining",
    src: "/img/project/dining-products.jpeg",
    src2: "/img/project/dining-products.jpeg",
    title: "Dining",
    description: "Transform your dining space with MR Exports elegant essentials. From stylish table runners to chic placemats, our collection offers everything you need for a refined dining experience. Complete the look with our premium tablecloths, and charming wine bottle covers. Explore our selection to create a dining setting that combines both style and functionality seamlessly."
  },
  {
    url: "/explore-products/other-made-ups",
    src: "/img/project/others.jpeg",
    src2: "/img/project/others.jpeg",
    title: "Other Made-ups",
    description: "Discover the versatility and functionality of our extensive collection of other made-ups at MR Exports. Designed to meet the diverse needs of your everyday life, our range includes expertly crafted laundry bags, stylish shopping bags, versatile tote bags, and more. Each product is thoughtfully created to combine practicality with aesthetic appeal, ensuring that you enjoy both functionality and style."
  },
  {
    src2: "/img/project/one.jpeg"
  },
  {
    src2: "/img/project/two.jpeg"
  },
  {
    src2: "/img/project/three.jpeg"
  },
  {
    src2: "/img/project/four.jpeg"
  },
  {
    src2: "/img/project/five.jpeg"
  },
  {
    src2: "/img/project/six.jpeg"
  },
  {
    src2: "/img/project/seven.jpeg"
  },
  {
    src2: "/img/project/eight.jpeg"
  },
  {
    src2: "/img/project/nine.jpeg"
  },
  {
    src2: "/img/project/ten.jpeg"
  }
];
