import React, { useEffect } from "react";
import AboutUs from "../../components/AboutUs/AboutUs";
import Banner from "../../components/Banner/Banner";
import ServicesArea from "../../components/ServicesArea/ServicesAreaFive";
import ProjectAllMasonry from "../../components/ProjectAreas/ProjectAllMasonry";
import Layout from "../../layouts/Layout";
import { gsapTitleAnimation } from "../../lib/gsap-lib/gsapTitleAnimation";

const HomeThree = () => {
  useEffect(() => {
    gsapTitleAnimation();
  }, []);

  return (
    <>
      <Layout>
        <Banner />
        <AboutUs />
        <ProjectAllMasonry />
        {/* <ServicesArea /> */}
        {/* <TestimonialAreaFour /> */}
      </Layout>
    </>
  );
};

export default HomeThree;
