import React, { useEffect } from "react";
import $ from "jquery";
import { Link } from "react-router-dom";
import siteSetting from '../../data/site-setting.json';


const MobileMenu = () => {

  useEffect(() => {
    //SubMenu Dropdown Toggle
    if ($(".menu-area li.menu-item-has-children ul").length) {
      $(".menu-area .navigation li.menu-item-has-children").append(
        '<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>'
      );
    }

    //Mobile Nav Hide Show
    if ($(".mobile-menu").length) {
      var mobileMenuContent = $(".menu-area .main-menu").html();
      $(".mobile-menu .menu-box .menu-outer").append(mobileMenuContent);

      //Dropdown Button
      $(".mobile-menu li.menu-item-has-children .dropdown-btn").on(
        "click",
        function () {
          $(this).toggleClass("open");
          $(this).prev("ul").slideToggle(300);
        }
      );
      //Menu Toggle Btn
      $(".mobile-nav-toggler").on("click", function () {
        $("body").addClass("mobile-menu-visible");
      });

      //Menu Toggle Btn
      $(".menu-backdrop, .mobile-menu .close-btn").on("click", function () {
        $("body").removeClass("mobile-menu-visible");
      });
    }
  }, []);

  return (
    <React.Fragment>
      <div className="mobile-menu">
        <nav className="menu-box">
          <div className="close-btn">
            <i className="fas fa-times"></i>
          </div>
          <div className="nav-logo">
            <Link to="/">
              <img src={siteSetting.logo} alt={siteSetting.siteName} />
            </Link>
          </div>
          <div className="menu-outer">
            {/* <!--Here Menu Will Come Automatically Via Javascript / Same Menu as in Header--> */}
          </div>
          <div className="social-links">
            <ul className="clearfix list-wrap">
              {siteSetting.socialMediallength > 0 && siteSetting.socialMedias.map((item, index) => (
                <li key={index}>
                  <a href={item.url}> <i className={item.icon}></i></a>
                </li>
              ))}
            </ul>
          </div>
        </nav>
      </div>
      <div className="menu-backdrop"></div>
    </React.Fragment>
  );
};

export default MobileMenu;
