import React from "react";
import { Link } from "react-router-dom";
import Footerdata from '../../data/site-setting.json'


const FooterBottom = () => {
  return (
    <div className="footer-bottom">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-md-7">
            <div className="copyright-text">
              <p>&copy; {new Date().getFullYear()}&nbsp;
                {Footerdata.siteName}. All Rights Reserved.
                Designed and Maintained by <Link to={Footerdata.devTeamLink} target="_blank">{Footerdata.devTeamName}</Link></p>
            </div>

          </div>

          <div className="col-lg-6 col-md-5">
            <div className="footer-bottom-menu">
              <ul className="list-wrap">
                <li>
                  {/* <Link to="/privacy-policy">Privacy Policy</Link> */}<p style={{ color: 'white' }}>Last Updated on: 20-06-2024</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterBottom;
