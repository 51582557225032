import React from "react";
import Service from '../../data/services.json'
import Products from '../../data/products.json'

const EstimateForm = () => {

  return (
    <form action="#">
      <div className="row">

        <div className="col-sm-4">
          <div className="form-group">
            <input type="text" className="form-control" placeholder="Name" />
          </div>
        </div>

        <div className="col-sm-4">
          <div className="form-group">
            <input type="tel" className="form-control" placeholder="Enter contact number" />
          </div>
        </div>

        <div className="col-sm-4">
          <div className="form-group">
            <input type="email" className="form-control" placeholder="Enter email address" />
          </div>
        </div>

        <div className="col-sm-4">
          <div className="form-group">
            <select id="shortBy" className="form-control">
              <option selected disabled>Product Type</option>
              {Object.keys(Service).map((category, index) => (
                <option key={index}>{category}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-sm-4">
          <div className="form-group">
            <select id="shortBy" className="form-control">
              <option selected disabled>Select Product</option>
              {Products.map((data, index) => (
                <option key={index}>{data.productName}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-sm-4">
          <div className="form-group">
            <select id="shortBy" className="form-control">
              <option selected disabled>Product For</option>
              <option>Personal Use</option>
              <option>Commercial Use</option>
            </select>
          </div>
        </div>

        <div className="col-sm-4">
          <div className="form-group">
            <input type="email" className="form-control" placeholder="Enter company name" />
          </div>
        </div>


        <div className="col-sm-4">
          <div className="form-group">
            <input type="email" className="form-control" placeholder="Enter company city and address" />
          </div>
        </div>


        <div className="col-sm-4">
          <div className="form-group">
            <input type="number" className="form-control" placeholder="Enter product count" />
          </div>
        </div>


        <div className="col-sm-12">
          <div className="form-group">
            <textarea className="form-control" rows="5" placeholder="Enter the product requirment / any additional information"></textarea>
          </div>
        </div>


      </div>
      <button type="submit" className="btn btn-two">
        Generate Estimation
      </button>
    </form>
  );
};

export default EstimateForm;
