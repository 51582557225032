import React, { useEffect, useState } from "react";
import ShopItem from "./ShopItem";
import Products from '../../data/products.json'
import { useParams } from "react-router-dom";

const ShopArea = ({ layout }) => {
  const { 'product-type': productType } = useParams();
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [sortingOption, setSortingOption] = useState("default");
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleEnquiryClick = (product) => {
    setSelectedProduct(product);
  };

  const handleClosePopup = () => {
    setSelectedProduct(null);
  };

  useEffect(() => {

    let filtered = Products;
    if (productType !== "all" && productType) {
      filtered = filtered.filter(product => product.category.replace(/\s+/g, '-').toLowerCase() === productType);
    }
    switch (sortingOption) {
      case "lowToHigh":
        filtered.sort((a, b) => a.productPricing - b.productPricing);
        break;
      case "highToLow":
        filtered.sort((a, b) => b.productPricing - a.productPricing);
        break;
      default:
        break;
    }

    setFilteredProducts(filtered);
  }, [productType, sortingOption]);


  const handleSortingChange = (e) => {
    setSortingOption(e.target.value);
  };

  return (
    <section className={`shop-area ${layout ? "" : "pt-130 pb-130"}`}>
      <div className="container">
        {!layout ? (
          <React.Fragment>
            <div className="shop-top-wrap mb-50">
              <div className="row align-items-center">
                <div className="col-lg-6 col-sm-6">
                  <div className="shop-showing-result">
                    <p>Showing all {filteredProducts.length} results</p>
                  </div>
                </div>

                {/* <div className="col-lg-6 col-sm-6">
                  <div className="shop-ordering">
                    <select name="orderby" className="orderby" onChange={handleSortingChange}>
                      <option value="default">Default sorting</option>
                      <option value="lowToHigh">Sort by Low to High</option>
                      <option value="highToLow">Sort by High to Low</option>
                    </select>
                  </div>
                </div> */}

              </div>
            </div>
          </React.Fragment>
        ) : ""}

        <div className="shop-item-wrap">
          <div className="row row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1">
            {layout ? filteredProducts.slice(0, 4).map((x, index) => (
              <div key={index} className="col">
                <ShopItem item={x}
                  selectedProduct={selectedProduct}
                  handleEnquiryClick={handleEnquiryClick}
                  handleClosePopup={handleClosePopup}
                />
              </div>
            )) : filteredProducts.map((x, index) => (
              <div key={index} className="col">
                <ShopItem item={x}
                  selectedProduct={selectedProduct}
                  handleEnquiryClick={handleEnquiryClick}
                  handleClosePopup={handleClosePopup}
                />
              </div>
            ))}
          </div>


        </div>
      </div>
    </section>
  );
};

export default ShopArea;
