import React from "react";
import { ProjectList } from "./ProjectList";
import { Link } from "react-router-dom";

const ProjectAllMasonry = () => {
  return (
    <section className="services-area-five">
      <div className="container">

        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="section-title-three text-center mb-60">
              <span className="sub-title">Our Products</span>
              <h2 className="title">Discover Our Finest Products</h2>
              <p>
                Explore our exquisite selection of home textile essentials meticulously designed to elevate your living spaces.
              </p>
            </div>
          </div>
        </div>


        <div className="project-item-wrap">
          <div className="row justify-content-center">
            {ProjectList.slice(0, 5).map((item, index) => (
              <div key={index} className="col-lg-4 col-md-6 col-sm-10">
                <div className="project-item inner-project-item">
                  <div className="project-thumb">
                    <Link to={item.url}>
                      <img src={item.src2} alt="" />
                    </Link>
                  </div>

                  <div className="project-content text-center">
                    <h2 className="title">
                      <Link to={item.url}>{item.title}</Link>
                    </h2>
                    {/* <h5 style={{ color: 'white', fontWeight: '100', fontSize: '15px' }}>{item.description}</h5> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <Link to="explore-products/all" style={{ display: "block", textAlign: "center", textDecoration: "underline" }}>View all Products</Link>
        
      </div>
    </section>
  );
};

export default ProjectAllMasonry;
